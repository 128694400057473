import React from 'react'
import clsx from 'clsx'
import styles from './Portfolio.module.scss'
import { portfolios } from '../../data'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Autoplay, Pagination } from 'swiper'
import Heading from '../base/Heading'

const Portfolio = () => {
  const pagination = {
    clickable: true,
    renderBullet: function(_, className) {
      return `<span class="${className}"></span>`
    }
  }

  return (
    <section className={clsx(styles.portfolio, 'section')} id="portfolio">
      <Heading>Portfolio</Heading>
      <div className={clsx(styles.content, 'container')}>
        <Swiper
          modules={[Autoplay, Pagination]}
          slidesPerView={1}
          pagination={pagination}
          speed={1000}
          spaceBetween={30}
        >
          <SwiperSlide>
            <div className={clsx(styles.list)}>
              {portfolios.map((portfolio, index) => (
                <figure key={index}>
                  <img src={portfolio.image} alt={portfolio.alt} />
                </figure>
              ))}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default Portfolio
