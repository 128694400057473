import clsx from 'clsx';
import React from 'react';
import styles from './Footer.module.scss';
import MenuLink from '../MenuLink';
import { menus } from '../../data';

const Footer = () => {
	return (
		<footer className={clsx(styles.footer, 'container')}>
			<figure className={clsx(styles.logo)}>
				<img src={require('../../assets/images/logo/pro-logo-mark.svg').default} alt='logo-mark' />
			</figure>

			<div className={clsx(styles.frame)}>
				<figure>
					<img src={require('../../assets/images/img/map.png').default} alt='map' />
				</figure>
				<div className={styles.content}>
					<div className={styles.list}>
						{menus.map((menu, index) => (
							<MenuLink key={index} label={menu.label} link={menu.link} className={clsx(styles.link)} />
						))}
					</div>
					<p className={styles.copyright}>© 2021 Procyon Ventures. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
