import React from 'react';
import clsx from 'clsx';
import styles from './Partners.module.scss';
import { partners } from '../../data';
import Heading from '../base/Heading';

const Partners = () => {
	return (
		<section className={clsx(styles.partners)} id='partners'>
			<Heading>Partners</Heading>
			<div className={clsx(styles.content, 'container')}>
				<div className={clsx(styles.list)}>
					{partners.splice(0, 9).map((partner, index) => (
						<div key={index} className={clsx(styles.item)}>
							<figure>
								<img src={partner.image} alt={partner.alt} />
							</figure>
							<div className={clsx(styles.info)}>
								{/* <h4>{partner.name}</h4> */}
								<p>{partner.desc}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Partners;
