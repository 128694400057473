import React from 'react';
import styles from './Base.module.scss';
import clsx from 'clsx';

const Heading = ({ children }) => {
	return (
		<div className={clsx(styles.heading)}>
			<div className={clsx(styles.title)}>
				<h2>{children}</h2>
			</div>
		</div>
	);
};

export default Heading;
