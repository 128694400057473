import React from 'react'
import styles from './Contact.module.scss'
import clsx from 'clsx'
// import { socials } from '../../data'

const Contact = () => {
  return (
    <section className={clsx(styles.contact, 'section')} id="contact">
      <div className={clsx(styles.content)}>
        {/* {socials.map((social, index) => (
          <a
            href={social.link}
            key={index}
            className={clsx(styles.link)}
            target={social.target}
          >
            <figure>
              <img src={social.icon} alt={social.name} />
            </figure>
            <span>{social.name}</span>
          </a>
        ))} */}
        <h2
          style={{
            width: '100%',
            fontSize: '38px',
            textAlign: 'center'
          }}
        >
          Contact With Us
        </h2>
        <p
          style={{
            width: '100%',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff'
          }}
        >
          contact@procyon.ventures
        </p>
      </div>
    </section>
  )
}

export default Contact
