import React from 'react'
import Banner from './Banner'
import About from './About'
import Portfolio from './Portfolio'
import Partners from './Partners'
import Contact from './Contact'
import MediaPartners from './Portfolio/MediaPartners'

const Content = () => {
  return (
    <div className="p-relative">
      <Banner />
      <About />
      <Portfolio />
      <Partners />
      <MediaPartners />
      <Contact />
    </div>
  )
}

export default Content
