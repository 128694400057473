import clsx from 'clsx';
import React from 'react';
import styles from './Banner.module.scss';
import logo from '../../assets/images/logo/pro-logo-full.png';
import circle from '../../assets/images/img/circle.png';
import mask from '../../assets/images/img/mask.png';
import whale from '../../assets/images/svg/whale.svg';

const Banner = () => {
	return (
		<section className={clsx(styles.banner, 'section')}>
			<div className={clsx(styles.logo)}>
				<img src={logo} alt='Logo Procyon' />
			</div>
			<div className={clsx(styles.content)}>
				<h2 className={clsx(styles.title)}>Tomorrow, built today</h2>

				<div className={clsx(styles.layerWhale)}>
					<figure className={clsx(styles.maskFish)}>
						<img src={mask} alt='' />
					</figure>
					<figure className={clsx(styles.circle)}>
						<img src={circle} alt='' />
					</figure>
					<figure className={clsx(styles.whale)}>
						<img src={whale} alt='' />
					</figure>
				</div>
				<div className={clsx(styles.empty)}></div>
				<p className={clsx(styles.text)}>SERVING MULTI-STAGE QUALITY BLOCKCHAIN PROJECTS ON A MISSION TO BUILD THE FUTURE.</p>
			</div>
		</section>
	);
};

export default Banner;
