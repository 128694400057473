import clsx from 'clsx';
import { useState } from 'react';
import styles from './Navbar.module.scss';
import MenuLink from '../MenuLink';
import { menus } from '../../data';
import Hambuger from '../../assets/images/svg/hambuger.svg';
import Close from '../../assets/images/svg/close.svg';

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<nav className={clsx(styles.menu)}>
			<div className={clsx(styles.collapse, { [styles.active]: isOpen })} onClick={() => setIsOpen(!isOpen)}>
				{isOpen ? <img src={Close} alt='close' /> : <img src={Hambuger} alt='hamburger' />}
			</div>
			<div className={clsx(styles.hambugerBg)}></div>
			<div className={clsx(styles.list)}>
				{menus.map((menu, index) => (
					<MenuLink key={index} label={menu.label} link={menu.link} className={clsx(styles.link)} onClick={() => setIsOpen(false)} />
				))}
			</div>
		</nav>
	);
};

export default Navbar;
