import React from 'react';
import clsx from 'clsx';
import styles from './About.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, Pagination } from 'swiper';
import Heading from '../base/Heading';
import { abouts } from '../../data';

const About = () => {
	const pagination = {
		clickable: true,
		renderBullet: function (_, className) {
			return `<span class="${className}"></span>`;
		},
	};

	return (
		<section className={clsx(styles.about, 'section')} id='about'>
			<Heading>About us</Heading>
			<div className={clsx(styles.content, 'container')}>
				<Swiper modules={[Autoplay, Pagination]} slidesPerView={1} pagination={pagination} speed={1000} spaceBetween={30}>
					{abouts.map((about, index) => (
						<SwiperSlide key={index}>
							<div className={clsx(styles.post)}>
								<h3 className={clsx(styles.title)}>{about.title}</h3>
								<p className={clsx(styles.text)}>{about.desc}</p>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export default About;
