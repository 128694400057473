export const partners = [
  {
    name: 'Poolz Ventures',
    desc:
      'Poolz Ventures is a capital firm focused on innovative blockchain projects with quality entrepreneur.',
    image: require('./assets/images/img/partners/poolz-ventures.png').default,
    alt: 'Poolz Ventures'
  },
  {
    name: 'Poolz',
    desc:
      'Decentralized Fundraising and Cross-Chain Token Launchpad. Propel your DeFi project, attract the right investors and community, and unravel the power of DeFi.',
    image: require('./assets/images/img/partners/poolz.svg').default,
    alt: 'Poolz'
  },
  {
    name: 'Verichains',
    desc:
      'Verichains provides high quality blockchain development and smart contract security audit services.',
    image: require('./assets/images/img/partners/verichains.webp').default,
    alt: 'Verichains'
  }
]

export const MEDIA_PARTNERS = [
  {
    image: require('./assets/images/img/partners/coinmarketcap.png').default,
    alt: 'Coin Market Cap'
  },
  {
    image: require('./assets/images/img/partners/coingecko.png').default,
    alt: 'Coingecko'
  },
  {
    image: require('./assets/images/img/partners/cointelegraph.png').default,
    alt: 'Coin Telegraph'
  },
  {
    image: require('./assets/images/img/partners/bscdaily.png').default,
    alt: 'BSC Daily'
  },
  {
    image: require('./assets/images/img/partners/bscnews.png').default,
    alt: 'BSC News'
  }
]

export const portfolios = [
  {
    image: require('./assets/images/img/porfolios/defish.png').default,
    alt: 'Defish'
  },
  {
    image: require('./assets/images/img/porfolios/destiny-world.png').default,
    alt: 'Destiny World'
  },
  {
    image: require('./assets/images/img/porfolios/space-sip.png').default,
    alt: 'Space Sip'
  },
  {
    image: require('./assets/images/img/porfolios/ws_white_horizontal.png')
      .default,
    alt: 'Wingswap'
  },
  {
    image: require('./assets/images/img/porfolios/dogegoal.png').default,
    alt: 'Dogegoal'
  },
  {
    image: require('./assets/images/img/porfolios/defiyied.png').default,
    alt: 'Defiyied'
  },
  {
    image: require('./assets/images/img/porfolios/defi-horse.png').default,
    alt: 'Defish Horse'
  },
  {
    image: require('./assets/images/img/porfolios/menzy.png').default,
    alt: 'Menzy'
  },
  {
    image: require('./assets/images/img/porfolios/metaapes.png').default,
    alt: 'Apes'
  },
  {
    image: require('./assets/images/img/porfolios/multichain.png').default,
    alt: 'Multi Chain'
  },
  {
    image: require('./assets/images/img/porfolios/img-logo-topgoal.png')
      .default,
    alt: 'Topgoal'
  },
  {
    image: require('./assets/images/img/porfolios/img-logo-animative.png')
      .default,
    alt: 'animative'
  },
  {
    image: require('./assets/images/img/porfolios/xwg.png').default,
    alt: 'xwg'
  },
  {
    image: require('./assets/images/img/porfolios/nfty-finance.png').default,
    alt: 'Nfty Finance'
  }
]

export const menus = [
  { label: 'About us', link: 'about' },
  { label: 'Portfolio', link: 'portfolio' },
  { label: 'Partners', link: 'partners' },
  { label: 'Contact', link: 'contact' }
]

export const socials = [
  {
    icon: require('./assets/images/svg/twitter-circle.svg').default
  },
  {
    icon: require('./assets/images/svg/telegram-circle.svg').default
  },
  {
    icon: require('./assets/images/svg/email-circle.svg').default,
    link: 'contact@procyon.ventures'
  },
  {
    icon: require('./assets/images/svg/medium-circle.svg').default
  }
]

export const abouts = [
  {
    title: 'Investment',
    desc:
      'Procyon Ventures is here to help you on your cryptocurrency and blockchain projects. We provide our experience building the blockchain project from ground up and support your venture journey!'
  },
  {
    title: 'Business Model Consultancy',
    desc:
      'Procyon ensures the projects are partnered with the best-in-class advisors who have the vast accumulated experience to better support and guide you through all stages, challenges, obstacles. We also promote faster growth for both parties involved!'
  },
  {
    title: 'Technical Consultancy',
    desc:
      'With our vast experience in the industry, Procyon Ventures can offer technical strategic guidance and practical support.'
  },
  {
    title: 'Art and Design',
    desc:
      'With our decades of experience in design, you can always rely on our team for any advice about how to best represent your project so that it is both aesthetically pleasing!'
  }
]
