import { Fragment } from 'react';
import Background from './components/Background';
import Content from './components/Content';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
	return (
		<Fragment>
			<Header />
			<Background />
			<Content />
			<Footer />
		</Fragment>
	);
}

export default App;
