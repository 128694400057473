import { Link } from 'react-scroll';
import { useViewport } from '../helper/viewport';
import { useMemo } from 'react';

const MenuLink = ({ label, link, className, onClick }) => {
	const viewPort = useViewport();
	const offset = useMemo(() => {
		switch (true) {
			case viewPort.width >= 1120:
				return 0;
			case viewPort.width >= 768:
				return -50;
			case viewPort.width <= 599:
				return 0;
			default:
				return 0;
		}
	}, [viewPort]);

	return (
		<>
			<Link
				to={link}
				className={className}
				spy={true}
				smooth={true}
				offset={offset}
				duration={500}
				onClick={() => {
					onClick();
				}}
			>
				{label}
			</Link>
		</>
	);
};

MenuLink.defaultProps = {
	onClick: () => {},
};

export default MenuLink;
