import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './Header.module.scss';
import Navbar from '../Navbar';

const Header = () => {
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const handleScroll = () => {
		setIsShow(window.pageYOffset > 20);
	};

	return (
		<header className={clsx(styles.header, { [clsx(styles.scroll)]: isShow })}>
			<Navbar />
		</header>
	);
};

export default Header;
